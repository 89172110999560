import React from "react";
import loadable from "@loadable/component";

const Layout = loadable(() => import("@common/Layout/Layout"));
const ErrorPage = loadable(() => import("@components/ErrorPage/ErrorPage"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const NotFoundVacancy = ({ path }) => (
    <>
        <SEO isNotIndexed />
        <Layout path={path}>
            <ErrorPage
                title="Vacancy Closed"
                firstDescription="The position has been filled or removed."
                secondDescription="Please, drop your CV here so we are able to contact"
                thirdDescription="you in the future regarding similar vacancies."
                buttonText="Drop CV"
                navigateTo="/careers/contact-us-cv"
            />
        </Layout>
    </>
);

export default NotFoundVacancy;
